var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.lang.videos)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"primary","small":"","depressed":""},on:{"click":function($event){return _vm.openCreateDialog()}}},[_vm._v(_vm._s(_vm.lang.newVideo))])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","append-icon":"mdi-magnify","label":_vm.lang.search},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.videos2.items,"hide-default-footer":"","loading":_vm.loading,"items-per-page":10,"show-select":false,"page":_vm.page,"loading-text":_vm.lang.loading,"search":_vm.search,"no-results-text":_vm.lang.noResults,"no-data-text":_vm.lang.noResults},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.url",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.displays",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.openUrl(item.url)}}},[_vm._v(_vm._s(item.url))])]}}])})],1)],1)],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.lang.newVideo))]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":_vm.lang.name},model:{value:(_vm.video.name),callback:function ($$v) {_vm.$set(_vm.video, "name", $$v)},expression:"video.name"}}),_c('v-text-field',{attrs:{"label":"Url","rules":_vm.urlRules},model:{value:(_vm.video.url),callback:function ($$v) {_vm.$set(_vm.video, "url", $$v)},expression:"video.url"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.createVideo()}}},[_vm._v(_vm._s(_vm.lang.create))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.lang.quit))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }