








































































import {Component, Mixins, Ref, Vue} from "vue-property-decorator";
import User from "@/models/User";
import VideoService from "@/services/VideoService";
import Video from "@/models/Video";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import PaginationMixin from "@/mixins/PaginationMixin";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";

@Component
export default class VideosView extends Mixins(PaginationMixin) {

	videos2: MultipleItem<Video> = { items: [], totalItems: 0 }

	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	videos: Video[] = []
	pageCount: number = 0
	loading: boolean = false
	search: string = ""
	video: Video = new Video()
	headers = [
		{text: "Id", value: "id", align: "center", width: "auto" },
		{text: this.lang.name, value: "name", align: "center", width: "auto" },
		{text: "Url", value: "url", align: "center", width: "auto"}
	]
	dialog: boolean = false
	urlRules = [
		(v: string) => v && v.length > 0 ? true : this.lang.urlRequired,
		(v: string) => StringTool.validateUrl(v) ? true : this.lang.urlNoValid
	]

	created() {
		this.refresh()
	}

	async refresh() {
		await Handler.getItems(this, this.videos2, () => VideoService.getVideos2(this.page - 1, this.size, this.search))
	}

	rowClick(video: Video) {
		this.$router.push({path: "/videos/" + video.id})
	}

	openUrl(url: string) {
		window.open(url, "_blank");
	}

	openCreateDialog() {
		if (this.form) this.form.resetValidation()
		this.dialog = true
		this.video = new Video()
	}

	createVideo() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
					this.lang.warning,
					this.lang.videoCreateQuestion,
					() => VideoService.postVideo(this, this.video)
			))
		}
	}

	noFilterEnabled() {
		alert("XDD")
	}
}
